
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.DescriptionContainerWrapper {
  background-color: $color-black-primary;
  padding: spacing(1.5);
  text-align: left;
  width: 100%;

  &.clickable:hover {
    background-color: $color-black-secondary;
  }

  .DescriptionWrapper {
    * > {
      display: inline;
    }
  }
}

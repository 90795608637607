
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Placeholder {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  min-height: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.MagazineCTAContainer {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.MagazineCTA {
  position: relative;

  @include mq($breakpoint-tablet) {
    margin-top: spacing(-1.5);
  }
}

.MagazineCTAWrapper {
  background-color: var(--color-theme--secondary);
  color: $color-white-primary;
  padding: spacing(1.2) spacing(2.5);
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;

  @include mq($breakpoint-tablet) {
    align-items: flex-start;
    padding: spacing(1.5);
  }

  img {
    max-width: 60px;
  }

  &:hover,
  &:focus {
    background-color: $color-grey-primary;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-width: 190px;
  margin-left: spacing(2.5);
}

.CTA {
  display: none;
  align-items: center;
  margin-top: auto;

  @include mq($breakpoint-tablet) {
    display: flex;
  }
}

.ArrowIcon {
  margin-left: spacing(1.1);
}

.Dismiss {
  position: absolute;
  top: spacing(0.75);
  right: spacing(0.75);

  svg {
    fill: $color-white-primary;
  }

  @include mq($breakpoint-tablet) {
    display: none;
  }
}

.hidden {
  &.MagazineCTA {
    display: none;
    @include mq($breakpoint-tablet) {
      display: flex !important; // Always display on > tablet
    }
  }
}

.MagazineCTA.large {
  margin-left: auto;
  margin-right: auto;
  padding: spacing(0.5);
  padding-top: spacing(0.5);
  background-color: var(--color-theme--secondary);
  max-width: 300px;
  margin-top: unset;

  &:hover,
  &:focus {
    background-color: $color-grey-primary;
  }

  .MagazineCTAWrapper {
    background-color: unset;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    gap: spacing(1);

    @include mq($breakpoint-tablet) {
      align-items: center;
      justify-content: space-between;
    }

    .Content {
      display: flex;
      margin-left: unset;
    }

    .ContentTop {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: spacing(1.5);

      .Title * {
        line-height: 1.2;
      }

      .Tag {
        display: inline-flex;
        background-color: $color-white-primary;
        color: $color-black-primary;
        padding: spacing(0.5) spacing(1);
      }
    }

    .CTA {
      background-color: var(--color-theme--primary);
      padding: spacing(1) spacing(2);
    }

    img {
      max-width: unset;
      width: 100%;
    }
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FieldQuestion {
  margin-bottom: spacing(1);
  display: inline-block;
  font-size: $font-body-16;

  @include mq($breakpoint-desktop) {
    font-size: $font-body-14;
  }
}

.disabled {
  color: $color-grey80;
}

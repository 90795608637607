
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.TextControl {
  display: block;
  width: 100%;
  font-family: inherit;
  background-color: $color-black-primary;
  transition: all $duration-standard $easing-standard;
  padding: spacing(1.5) - 1 spacing(1.5);
  color: $color-white-primary;
  line-height: spacing(3);
  caret-color: var(--color-theme--secondary);
  font-size: 16px;

  // -webkit-appearance: none;
  border: 2px solid $color-grey-primary;
  border-radius: 0;

  &::placeholder {
    font-size: 16px;

    @include mq($breakpoint-desktop) {
      font-size: 12px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    border-color: var(--color-theme--secondary);
  }

  &:focus {
    outline: 0;
  }

  // &[type='date'] {
  //   appearance: none;
  //   height: spacing(6);
  // }
}

.transparentBg {
  background-color: transparent;
  border: $color-white-primary 1.5px solid;
}

.multiLine {
  height: auto;
  resize: vertical;
}

@each $key, $value in $color-status-list {
  .#{$key} {
    border-color: $value !important;
  }
}

.disabled {
  pointer-events: none;
  color: $color-grey80 !important;
  border-color: $color-grey80 !important;
}

.inverse {
  background-color: $color-white-primary;
  color: $color-black-primary;
}

.large {
  padding: spacing(2.1) - 1 spacing(2.1);
}

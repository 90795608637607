
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.StoryCTAContainer {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.StoryCTA {
  position: relative;
}

.StoryCTAWrapper {
  background-color: var(--color-theme--secondary);
  color: $color-white-primary;
  padding: spacing(1.2) spacing(2.5);
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;

  @include mq($breakpoint-tablet) {
    align-items: stretch;
    padding: spacing(1.5);
    max-width: 322px;
  }

  img {
    max-width: 60px;
  }

  &:hover,
  &:focus {
    background-color: $color-grey-primary;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 190px;
  margin-left: spacing(2.5);
}

.CTA {
  display: none;
  align-items: center;
  margin-top: auto;

  @include mq($breakpoint-tablet) {
    display: flex;
  }
}

.ArrowIcon {
  margin-left: spacing(1.1);
}

.Dismiss {
  position: absolute;
  top: spacing(0.75);
  right: spacing(0.75);

  svg {
    fill: $color-white-primary;
  }

  @include mq($breakpoint-tablet) {
    display: none;
  }
}

.hidden {
  &.StoryCTA {
    display: none;
    @include mq($breakpoint-tablet) {
      display: flex !important; // Always display on > tablet
    }
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SpeakerCard {
  position: relative;
  // padding: spacing(2);
  // background-color: $color-grey95;
  // border-bottom: spacing(0.5) solid var(--color-theme--event);
  transition: outline 0.2s;
  // &.center {
  //   .Title,
  //   .Subtitle,
  //   .CompanyName,
  //   .NextAgendaItem {
  //     text-align: center;
  //     max-width: 100%;
  //   }

  //   .Roles {
  //     justify-content: center;
  //   }
  // }

  &.grey {
    // background-color: $color-white-primary;
    .LogoWrapper {
      background-color: $color-white-primary;
    }
  }

  &.dark {
    .Title {
      color: $color-white-primary;
    }

    .Subtitle {
      color: rgba($color-white-primary, 0.4);
    }

    .CompanyName {
      color: $color-white-primary;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      .Title {
        text-decoration: underline;
      }

      .Play svg path {
        fill: var(--color-theme--event) !important;
      }

      .ImageWrapper {
        box-shadow: 0 4px 16px rgba($color-black-primary, 0.5);
      }
    }
  }
}

.TextContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
}

.CompanyName {
  color: $color-black-primary;
}

.Title {
  color: $color-black-primary;
}

.Subtitle {
  color: $color-grey-secondary;
}

.NextAgendaItem {
  display: flex;
  gap: spacing(0.5);
  flex-wrap: wrap;
  align-items: center;

  .Calendar {
    margin-top: spacing(-0.5);

    path {
      fill: var(--color-theme--event);
    }
  }

  .NextAgendaItemName {
    text-decoration: underline;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      color: var(--color-theme--event);
      cursor: pointer;
    }
  }
}

.ImageWrapper {
  position: relative;
  height: 0;
  padding-top: 75%;
  align-self: center;
  background-color: $color-grey50;
  transition: box-shadow 0.1s ease-in-out;

  .Image {
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0%;
    height: 100%;
    width: 100%;
  }

  .Logo {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(8px, -8px);
    object-fit: contain;
    width: 100%;
    max-width: spacing(5);
  }

  .Play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.SpeakerCardInner {
  .ImageWrapper {
    padding-top: 100%;

    .Image {
      overflow: hidden;
    }
  }
}

.LogoWrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  max-width: spacing(10);
  background-color: $color-grey95;
  display: flex;
  justify-content: center;
  align-items: center;
  .Logo {
    object-fit: contain;
    width: 80%;
    height: 80%;
  }
}

.Roles {
  display: flex;
  gap: spacing(1);
  flex-wrap: wrap;

  .Role {
    color: var(--color-theme--event);
    border: 1px solid var(--color-theme--event);
    padding: spacing(0.25) spacing(0.5);
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventContentGrid {
  padding-top: spacing(8);
  padding-bottom: spacing(8);
  position: relative;
  overflow: hidden;

  @include mq($breakpoint-tablet) {
    padding-top: spacing(6);
    padding-bottom: spacing(6);
  }

  .Stack {
    position: relative;
  }

  .Grid {
    z-index: 1;
  }

  .Shape1,
  .Shape2 {
    display: none;
  }

  // ALIGNMENT
  &.center {
    .Grid {
      justify-content: center;
    }

    .Title,
    .Subtitle {
      text-align: center;
    }
  }

  // THEMES
  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    background-color: $color-black-brand;

    .Shape1,
    .Shape2 {
      display: block;
      position: absolute;
      z-index: 0;
    }

    .Shape1 {
      left: 0;
      transform: translate(-50%, -50%);
    }

    .Shape2 {
      right: 0;
      bottom: 0;
      transform: translate(60%, 50%);

      path {
        fill: var(--color-theme--event) !important;
      }
    }

    .Title {
      color: $color-white;
      .Highlight {
        color: var(--color-theme--event) !important;
      }
    }

    .Subtitle {
      color: $color-white;
    }

    button,
    a span {
      color: $color-white-primary !important;
    }
  }

  &.video {
    border-top: 2px solid var(--color-theme--event);

    .StackInner {
      @include mq($breakpoint-tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.Subtitle {
  margin-top: spacing(1);
}

.ButtonGroup {
  &.videoDesktopOnly {
    display: none;
    @include mq($breakpoint-tablet) {
      display: block;
      margin-top: unset;
    }
  }

  &.videoMobileOnly {
    display: block;
    @include mq($breakpoint-tablet) {
      display: none;
    }
  }
}

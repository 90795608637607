
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.CarouselWrapper {
  position: relative;
}

.Carousel {
  display: block;
  overflow: hidden;

  [class*='flickity-slider'] {
    > * {
      margin-right: spacing(3);

      @include mq($max: $breakpoint-desktop - 1) {
        width: 75% !important;

        .is-draggable & {
          height: 100%;
        }
      }
    }
  }

  [class*='is-draggable'] {
    // Ensure carousel items are the full height of their container
    // only possible once the "is-draggable" class has been added by flickity
    > div > div > div {
      height: 100%;
    }
  }
}

.CarouselPaginationSummary {
  @include mq($breakpoint-desktop) {
    position: absolute;
    transform: translate(0, 50%);
    left: 0;
    right: spacing(-3);

    &.hidden {
      display: none;
    }
  }
}

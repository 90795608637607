
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventMapWrapper {
  display: flex;
  flex-direction: column;
  background-color: $color-black-primary;
  padding-top: spacing(4);

  @include mq($breakpoint-desktop) {
    padding-top: unset;
  }

  .Buttons {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: spacing(8);

    span {
      color: white;
    }
  }
}

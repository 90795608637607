
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Block {
  width: 100%;
  height: unset !important;
  @include mq($breakpoint-desktop) {
    padding-top: spacing(8);
    padding-bottom: spacing(8);
  }
  .BlockInner {
    @include mq($breakpoint-desktop) {
      padding-left: spacing(8);
      padding-right: 0;
      display: flex;
    }
    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-desktop) {
        flex: 1.5;
        margin-bottom: unset;
        display: grid;
      }

      .ImageWrapper {
        position: relative;
        height: 0;
        padding-top: 50.25%;
        align-self: center;

        .Image {
          position: absolute;
          object-fit: cover;
          left: 0;
          top: 0%;
          height: 100%;
          width: 100%;
        }

        .Logo {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          object-fit: contain;
          height: 100%;
          width: 100%;
          max-width: spacing(10);
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(2);
      padding-left: spacing(2);
      padding-bottom: spacing(8);
      @include mq($breakpoint-desktop) {
        padding-top: unset;
        padding-left: unset;
        padding-bottom: unset;
        flex: 1.5;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      @include mq($breakpoint-desktop) {
        flex: 1;
      }

      .Content {
        padding-left: spacing(0);
        padding-right: spacing(2);
        @include mq($breakpoint-desktop) {
          padding-left: spacing(4);
          padding-right: spacing(4);
        }
        @include mq($breakpoint-desktopMedium) {
          padding-left: spacing(6);
          padding-right: spacing(6);
          max-width: spacing(70);
        }

        .Buttons {
          display: flex;
          gap: spacing(1);
        }
      }
    }

    &.inverse {
      @include mq($breakpoint-tablet) {
        padding-right: spacing(8);
        padding-left: 0;
      }

      .ImagesWrapper {
        @include mq($breakpoint-desktop) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-desktop) {
          order: 1;
        }
      }
    }
  }

  // eslint-disable-next-line declaration-empty-line-before
  &.dark {
    @include darkMode;

    background-color: $color-black-brand;
  }
  // eslint-enable-next-line declaration-empty-line-before
}

.Tag {
  display: inline-flex;
  padding: spacing(0.5) spacing(1);
  background-color: $color-black-primary;
  color: $color-white;
}

.Title {
  font-weight: $font-weight-bold;
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}

.Subtitle {
  color: var(--color-theme--event) !important;
}

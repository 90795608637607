
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.BrandMap {
  background-color: $color-black-primary;
  color: $color-white-primary;
  padding: spacing(4.5) 0 spacing(1.5);
  @include mq($breakpoint-desktop) {
    padding: spacing(8) 0 spacing(6.5);
  }
}

.Title {
  max-width: 450px;
  margin: 0 0 spacing(1.5);

  @include mq($breakpoint-desktop) {
    min-width: 420px;
    margin-right: spacing(6.25);
    margin-bottom: 0;
  }
}

.BrandMapContainer {
  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: center;

    .Map {
      max-width: 835px;
      flex: 1 1 835px;
    }
  }
}

.StatsContainer {
  @include mq($breakpoint-desktop) {
    margin-top: spacing(6);
  }

  @include mq($breakpoint-desktopMedium) {
    margin-top: spacing(0.5);
  }
}

.Stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include mq($breakpoint-tabletLandscape) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  @include mq($breakpoint-desktop) {
    transform: translateX(spacing(-5));
    justify-content: flex-end;
  }

  @include mq($breakpoint-desktopMedium) {
    transform: translateX(spacing(-14));
  }
}

.StatsWrapper {
  list-style: none;
  width: 100%;

  &:not(:last-child) {
    margin: 0 0 spacing(4);
  }

  @include mq($breakpoint-tabletLandscape) {
    flex: 1 1 210px;
    max-width: 210px;
    &:not(:last-child) {
      margin: 0 spacing(7.5) 0 0;
    }
  }
}

.StatsItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-theme--secondary);
  padding-bottom: spacing(1.5);
  margin-bottom: spacing(1.5);

  &.large {
    padding-bottom: spacing(1);
    margin-bottom: spacing(1.5);
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventArticleSplitHero {
  .EventArticleSplitHeroInner {
    @include mq($breakpoint-tablet) {
      display: flex;
      min-height: 350px;
    }

    @include mq($breakpoint-desktop) {
      min-height: 450px;
    }

    @include mq($breakpoint-desktopMedium) {
      min-height: 550px;
    }

    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-tablet) {
        flex: 1;
        margin-bottom: unset;
      }

      .ImageWrapper {
        position: relative;

        .Image {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(8);
      padding-bottom: spacing(8);
      @include mq($breakpoint-tablet) {
        flex: 1.5;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include mq($breakpoint-desktop) {
        flex: 1;
      }

      .Content {
        padding-left: spacing(2);
        padding-right: spacing(2);
        @include mq($breakpoint-tablet) {
          padding-left: spacing(4);
          padding-right: spacing(4);
        }
        @include mq($breakpoint-desktopMedium) {
          padding-left: spacing(6);
          padding-right: spacing(6);
          max-width: spacing(80);
        }
      }
    }

    &.inverse {
      .ImagesWrapper {
        @include mq($breakpoint-tablet) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-tablet) {
          order: 1;
        }
      }
    }
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-primary;
  }
}

.Title {
  font-weight: $font-weight-extrabold;
  text-transform: uppercase;
  font-style: italic;
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventWOFSplitHero {
  .EventWOFSplitHeroInner {
    @include mq($breakpoint-tablet) {
      display: flex;
    }

    .ImageWrapper {
      position: relative;
      width: 100%;
      padding-top: 56%;

      @include mq($breakpoint-tablet) {
        flex: 2;
        margin-bottom: unset;
        padding-top: 38%;
      }
    }

    .Image {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &.inverse {
      .ImageWrapper {
        @include mq($breakpoint-tablet) {
          order: 2;
        }
      }
    }
  }

  .ContentWrapper {
    padding-top: spacing(8);
    padding-bottom: spacing(2);
    @include mq($breakpoint-tablet) {
      flex: 1.5;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: spacing(8);
    }
    @include mq($breakpoint-desktop) {
      flex: 1;
    }

    .Content {
      padding-left: spacing(2);
      padding-right: spacing(2);
      @include mq($breakpoint-tablet) {
        padding-left: spacing(4);
        padding-right: spacing(4);
      }
      @include mq($breakpoint-desktopMedium) {
        max-width: spacing(70);
      }
    }
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-brand;

    .Highlight {
      color: $color-white-primary !important;
    }
  }
}

.Title {
  font-weight: $font-weight-regular;
  max-width: spacing(40);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }

  .Highlight {
    font-weight: $font-weight-bold;
    color: $color-black-primary !important;
  }

  .CircleWrapper {
    display: inline-flex;
  }

  .Circle {
    position: relative;
    margin-left: spacing(0.5);
    top: spacing(-0.125);
    width: 8px !important;
    height: 8px !important;
    @include mq($breakpoint-desktop) {
      width: 15px !important;
      height: 15px !important;
      top: spacing(0.5);
    }

    &:nth-child(1) {
      margin-left: spacing(0.5);
    }

    svg {
      height: 100%;
    }
  }

  .blue {
    path {
      fill: #2e7aff;
    }
  }

  .yellow {
    path {
      fill: #ffba50;
    }
  }

  .red {
    path {
      fill: #ff3e4c;
    }
  }
}

.SocialsList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: spacing(2);
  margin-top: spacing(2);

  .SocialsIconButton {
    position: relative;
    background-color: #3a3a3a;
    border-radius: 100%;
    padding: spacing(2);
    transition: background-color 0.2s ease;

    svg path {
      fill: $color-white-primary;
    }

    &:hover,
    &:focus {
      background-color: #4e4e4e;
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

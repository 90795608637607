// Primary

$color-black-primary: #202121;
$color-white-primary: #fff;
$color-grey-primary: #646464;

// Secondary

$color-black-secondary: #2a2c2c;
$color-white-secondary: #efefef;
$color-grey-secondary: #989898;

$color-button-hover: #b6803f;

// Brand
$color-black-brand: #0f0f0f;

// Miscellaneous

$color-misc-red: #ef3d3b;
$color-misc-twitter: #1da1f2;
$color-misc-linkedin: #0a66c2;
$color-misc-green: #08c53d;
$color-misc-black: #000;
$color-misc-overlay: #171717;
$color-misc-divider: #d1d1d1;
$color-misc-ad: #f5f5f5;
$color-misc-caption: #f8f8f8;
$color-misc-magazine-viewer: #414141;
$color-misc-grey: #b3b3b3;

// Services

$color-service-business: #48a0ff;
$color-service-connect: #ffba50;
$color-service-lifestyle: #39dcc7;
$color-service-intelligence: #ac7eff;
$color-service-studio: #ff8e69;
$color-service-networks: #ff4651;

$color-status-success: $color-misc-green;
$color-status-notice: #01579b;
$color-status-warning: #ff6f00;
$color-status-error: $color-misc-red;

$color-status-list: (
  success: $color-status-success,
  notice: $color-status-notice,
  warning: $color-status-warning,
  error: $color-status-error
);

// Left in place so as not to cause errors. To be deleted as their references are removed from the project
// -------------

$color-green: #3bae70;
$color-purple: #7251a1;
$color-yellow: #f4d40e;
$color-black: $color-misc-black;
$color-grey80: $color-misc-divider;
$color-grey85: $color-misc-divider;
$color-grey95: $color-misc-ad;
$color-white: $color-white-primary;

$color-grey20: hsl(0, 0%, 20%);
$color-grey50: hsl(0, 0%, 50%);
$color-grey75: hsl(0, 0%, 75%);

$color-primary: $color-green;
$color-primary-light: #1976d2;
$color-primary-dark: #0d47a1;

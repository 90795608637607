
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventTestimonial {
  position: relative;
  padding-top: spacing(1);
  padding-bottom: spacing(1);
  border: 2px solid $color-grey75;

  &.light {
    border: 2px solid $color-grey75;
  }

  &.grey {
    background-color: $color-grey75;
    border: unset;
  }

  &.dark {
    background-color: $color-black-primary;
    border: unset;
    @include darkMode;
  }

  &.primary {
    background-color: var(--color-theme--event);
    border: unset;

    .QuoteeOverride,
    .SpeakerThumbnailJobTitle {
      color: $color-white;
    }
  }

  &.lightgrey {
    background-color: $color-grey95;
    border: unset;
  }

  &.dark,
  &.grey,
  &.primary {
    .Headline {
      color: $color-white !important;
    }
  }

  .EventTestimonialInner {
    position: relative;
    @include mq($breakpoint-tablet) {
      display: flex;
    }
    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-tablet) {
        flex: 1;
        margin-bottom: unset;
      }

      .ImageWrapper {
        position: relative;

        .Image {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(2);
      padding-bottom: spacing(2);

      .Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &.inverse {
      .ImagesWrapper {
        @include mq($breakpoint-tablet) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-tablet) {
          order: 1;
        }
      }
    }
  }
}

.EventTestimonialImage {
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: red;
}

.Headline {
  font-weight: $font-weight-extrabold;
  text-transform: uppercase;
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}

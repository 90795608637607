
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.PaginationSummary {
  //
}

.PaginationSummaryButton {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &.prev {
    left: spacing(-3);
  }

  &.next {
    right: 0;
  }
}

.PaginationSummaryProgress {
  background: $color-misc-divider;
  position: relative;
  height: 2px;
  margin: spacing(4) auto 0;
  width: 75%;

  > div {
    flex-grow: 1;
  }
}

.PaginationSummaryProgressSegment {
  position: absolute;
  top: 0;
  bottom: 0;
  transition: left $duration-shorter;
  background: $color-black-primary;
}

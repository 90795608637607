
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.IconButton {
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0;
  }
}

.IconButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    vertical-align: top;
  }
}

.IconButtonText {
  margin-left: spacing(0.5);
}

.rounded {
  border-radius: 50%;
}

.small {
  height: spacing(4);
  line-height: spacing(4);
  min-width: spacing(4);
}

.medium {
  height: spacing(5);
  line-height: spacing(5);
  min-width: spacing(5);
}

.themed {
  color: $color-white-primary;
  background-color: var(--color-theme--secondary);

  &:focus,
  &:hover,
  &:active {
    background-color: $color-grey-secondary;
  }
}

.increaseHitArea {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: spacing(-1.5);
    right: spacing(-1.5);
    bottom: spacing(-1.5);
    left: spacing(-1.5);
  }
}

.shadow {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3);
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Prose {
  // Unset common styles left in place by WYSIWYG editor
  $prose-overrides: 'box-sizing', 'color', 'font-family', 'font-size',
    'font-style', 'font-weight', 'line-height', 'margin', 'padding',
    'text-decoration', 'white-space';

  @each $property in $prose-overrides {
    [style*='#{$property}'] {
      #{$property}: inherit !important;
    }
  }

  &.noTicker a[name][id] {
    margin-top: -75px;
    padding-top: 75px;

    @include mq($breakpoint-desktop) {
      margin-top: -185px;
      padding-top: 185px;
    }
  }

  > * + * {
    margin-top: spacing(3);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-body;
    line-height: 120%;
    font-weight: 500;
  }

  > {
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: spacing(2);
      @include mq($breakpoint-desktop) {
        margin-top: spacing(4);
      }
    }
  }

  //TODO: IMPROVE

  &.march8 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-bodoni;
    }
  }

  h1 {
    font-size: 20px;

    & + * {
      margin-top: spacing(1);
    }

    @include mq($breakpoint-desktop) {
      font-size: 32px;
      & + * {
        margin-top: spacing(2);
      }
    }
  }

  h2 {
    font-size: 20px;

    & + * {
      margin-top: spacing(1);
    }

    @include mq($breakpoint-desktop) {
      font-size: 24px;
      & + * {
        margin-top: spacing(2);
      }
    }
  }

  h3 {
    font-size: 18px;
    & + * {
      margin-top: spacing(1);
    }
    @include mq($breakpoint-desktop) {
      font-size: 28px;
      & + * {
        margin-top: spacing(2);
      }
    }
  }

  h4,
  h5,
  h6 {
    font-size: 16px;
    & + * {
      margin-top: spacing(1);
    }
  }

  h4 {
    @include mq($breakpoint-desktop) {
      font-size: 26px;
    }
  }

  h5 {
    @include mq($breakpoint-desktop) {
      font-size: 24px;
    }
  }

  h6 {
    @include mq($breakpoint-desktop) {
      font-size: 20px;
    }
  }

  em {
    font-style: italic;
  }

  li {
    list-style-position: outside;
    margin-left: spacing(2);
  }

  ul {
    li {
      list-style-type: disc;

      li {
        list-style-type: circle;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;

      li {
        list-style-type: lower-roman;
      }
    }
  }

  a {
    text-decoration: none;
    color: var(--color-theme--secondary);
    display: inline;

    // https://css-tricks.com/better-line-breaks-for-long-urls/
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;

    &:hover,
    &:active,
    &:focus {
      outline: 0;
      text-decoration: underline;
    }

    &[name][id] {
      margin-top: -120px;
      padding-top: 120px;

      @include mq($breakpoint-desktop) {
        margin-top: -225px;
        padding-top: 225px;
      }

      &:hover {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }

  p,
  li {
    @include mq($breakpoint-desktop) {
      font-size: 18px;
      line-height: 1.6; // 28.8px
    }
  }

  hr {
    height: 0;
    opacity: 0.5;
    border: 0;
    border-bottom: 1px solid currentColor;
    margin-bottom: -1px;
  }

  iframe[src*='youtube'] {
    width: 320px;
    height: 180px;
    max-width: 100%;

    @include mq($breakpoint-tablet) {
      width: 480px;
      height: 270px;
    }

    @include mq($breakpoint-desktop) {
      width: 640px;
      height: 360px;
    }
  }

  // Last in the group for specificity reasons
  > *:first-child {
    margin-top: 0;
  }
}

.inverse {
  color: $color-white;
  a {
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;
    }
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.OutlineHeading {
  display: inline-block;
}

.Heading {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  transform: skew(-8deg);
}

.Heading em {
  color: $color-misc-red;
  font-style: normal;

  @supports (-webkit-text-stroke: $color-misc-red) {
    color: transparent;
    -webkit-text-stroke: 1px $color-misc-red;

    @include mq($breakpoint-desktop) {
      -webkit-text-stroke: 2px $color-misc-red;
    }

    .white & {
      color: $color-white-primary;

      @supports (-webkit-text-stroke: $color-white-primary) {
        color: transparent;
        -webkit-text-stroke: 1px $color-white-primary;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}

.SubHeading {
  text-transform: uppercase;
  letter-spacing: 0.3em;
  color: var(--color-theme--secondary);
  white-space: nowrap;
}

.Divider {
  border-bottom: 1px solid var(--color-theme--secondary);
  position: relative;
  margin-bottom: 1px;

  &::before,
  &::after {
    content: '';
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background-color: var(--color-theme--secondary);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventHero {
  position: relative;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  overflow: hidden;

  @include mq($breakpoint-desktop) {
    height: 0;
    padding-bottom: 45%;
    position: relative;
  }

  @include mq($breakpoint-desktopMedium) {
    padding-bottom: 35%;
  }

  @include mq($breakpoint-desktopLarge) {
    padding-bottom: 29%;
  }

  &.hideEventLogo {
    @include mq($breakpoint-desktop) {
      padding-bottom: 37.5%;
    }

    @include mq($breakpoint-desktopMedium) {
      padding-bottom: 30%;
    }

    @include mq($breakpoint-desktopLarge) {
      padding-bottom: 22%;
    }

    .Logo {
      display: none;
    }
  }

  &.alignLeft {
    .TitleText,
    .Description {
      margin-left: unset;
    }

    .Stack {
      text-align: left !important;
      align-items: flex-start !important;
    }

    .ContentWrapper {
      @include mq($breakpoint-desktop) {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }

    .ButtonGroup {
      @include mq($breakpoint-desktop) {
        justify-content: flex-start;
      }
    }

    .Title,
    .Subtitle,
    .Description {
      margin-left: unset;
    }
  }

  &.useAsHeading {
    padding-top: spacing(1);
    padding-bottom: spacing(1);
    height: auto;

    @include mq($breakpoint-desktop) {
      padding-top: spacing(4);
      padding-bottom: spacing(4);
    }

    .EventHeroInner {
      @include mq($breakpoint-desktop) {
        position: relative;
        left: unset;
        transform: unset;
        top: unset;
        width: unset;
      }
    }

    .ContentWrapper {
      padding-top: spacing(0) !important;
      padding-bottom: spacing(0) !important;
      @include mq($breakpoint-desktop) {
        padding-top: spacing(0) !important;
        padding-bottom: spacing(0) !important;
      }
    }
  }
  &.useAsHeading.sponsor {
    padding-top: spacing(4);
    padding-bottom: spacing(4);
    height: auto;

    @include mq($breakpoint-desktop) {
      padding-top: spacing(4);
      padding-bottom: spacing(4);
    }
  }

  &.useAsHeading.sponsor.hideEventLogo {
    padding-top: spacing(6);
    padding-bottom: spacing(4);
  }

  &.sponsor {
    .TitleWrapper {
      gap: spacing(2);
    }

    .Subtitle {
      background-color: rgba(0, 0, 0, 0.8);
      padding: spacing(0.5) spacing(1);
      color: $color-white-primary;
    }

    .Highlight {
      color: $color-black-primary !important;
    }
  }

  &.sponsor.dark {
    .Highlight {
      color: $color-white-primary !important;
    }
  }

  .EventHeroInner {
    position: relative;
    z-index: 2;
    @include mq($breakpoint-desktop) {
      display: flex;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-desktop) {
        flex: 1;
        margin-bottom: unset;
      }

      .ImageWrapper {
        position: relative;

        .Image {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    .Stack {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .ContentWrapper {
      padding-top: spacing(8);
      padding-bottom: spacing(8);
      @include mq($breakpoint-desktop) {
        flex: 1.5;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include mq($breakpoint-desktop) {
        flex: 1;
      }
    }

    &.inverse {
      .ImagesWrapper {
        @include mq($breakpoint-desktop) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-desktop) {
          order: 1;
        }
      }
    }
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-brand;

    .YoutubeEmbedOverlay {
      background-color: rgba(0, 0, 0, 0.2);
      background-image: radial-gradient(rgba(0, 0, 0, 0.4), transparent);
    }
  }
}

.EventHeroImage {
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-black-primary;

  @include mq($breakpoint-desktop) {
    &.parallax {
      background-attachment: fixed;
    }
  }
}

.SponsorLogo {
  max-width: 75px;
  background: $color-white-primary;
  /* stylelint-disable-next-line property-no-unknown */
  aspect-ratio: 1 / 1;

  @include mq($breakpoint-tablet) {
    max-width: 100px;
  }
}

.Logo {
  max-height: 60px;
  max-width: 230px;
}

.TitleWrapper {
  display: flex;
  flex-direction: column;

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    align-items: flex-end;
    gap: spacing(2);
    margin-bottom: spacing(2);
  }

  @include mq($breakpoint-desktop) {
    align-items: flex-start;
  }
}

.TitleText {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  margin-left: auto;
  margin-right: auto;
}

.Title {
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
    margin-left: auto;
    margin-right: auto;
  }
}

.Subtitle {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.Description {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .DescriptionInner {
    p {
      font-size: inherit !important;
    }

    li {
      font-size: 0.8em !important;
    }
  }
}

.ButtonGroup {
  @include mq($breakpoint-desktop) {
    display: flex;
    justify-content: center;
  }
}

.YoutubeEmbed {
  position: absolute;
  width: 400vw;
  height: 185vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  @include mq($breakpoint-tablet) {
    width: 200vw;
  }

  @include mq($breakpoint-desktop) {
    height: 135vh;
  }

  .YoutubeEmbedOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    pointer-events: none;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &.show {
    opacity: 1;
  }
}

$font-body: 'Rotunda', arial, sans-serif; /* stylelint ignore */
$font-bodoni: 'Bodoni Moda', serif;

$font-heading-zero: 52px;
$font-heading-one: 40px;
$font-heading-two: 32px;
$font-heading-three: 28px;
$font-heading-four: 26px;
$font-heading-five: 24px;
$font-heading-six: 20px;
$font-heading-seven: 18px;
$font-heading-eight: 16px;

$font-body-24: 24px;
$font-body-20: 20px;
$font-body-16: 16px;
$font-body-14: 14px;
$font-body-12: 12px;
$font-body-10: 10px;

$font-display-zero: 72px;
$font-display-one: 60px;
$font-display-two: 44px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

$font-height-120: 120%;
$font-height-130: 130%;
$font-height-140: 140%;
$font-height-160: 160%;
$font-height-220: 220%;

$font-tracking-2: 0.2em;
$font-tracking-3: 0.3em;
$font-tracking-4: 0.4em;
$font-tracking-5: 0.52em;


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.LivePlayerWrapper {
  background: $color-misc-black;
  padding-top: spacing(4);
  padding-bottom: spacing(4);

  .SelectLabel {
    color: white;
    font-size: spacing(1.5);
    text-transform: uppercase;
  }

  .SelectControl {
    background: $color-misc-black;
    color: white;
    border: none;
    box-shadow: none;
    padding: 0;
    font-size: spacing(2);
    font-weight: bold;
    height: unset;
  }
}

.LivePlayer {
  position: relative;
  min-height: 0;
  width: 100%;
  padding-top: calc((9 / 16) * 100%);

  .VimeoEmbed {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
}

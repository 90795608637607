
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FieldRequired {
  margin-left: 6px;
  color: $color-status-error;
}

abbr.FieldRequired[title] {
  text-decoration: none;
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ProgramContent {
  color: $color-black-primary !important;
  background: $color-white-primary !important;
  border-radius: 0 !important;
  transition: all 0.1s !important;

  &:hover {
    outline: spacing(0.5) var(--color-theme--event) solid;
  }

  &.live {
    background: var(--color-theme--event) !important;

    .ProgramText {
      &::before {
        content: '|';
        color: $color-white-primary;
      }
    }
  }
}

.ProgramTitle,
.ProgramText {
  color: $color-black-primary !important;
  font-weight: $font-weight-bold !important;
}

.ProgramText {
  &::before {
    content: '|';
    font-weight: $font-weight-bold;
    color: $color-misc-green;
    margin-right: spacing(1);
  }
}

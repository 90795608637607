
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.TierTwoSponsor {
  width: 100%;
  height: unset !important;
  background-color: $color-grey95;
  padding-bottom: spacing(2);

  .TierTwoSponsorInner {
    @include mq($breakpoint-tabletLandscape) {
      padding-left: unset;
      display: flex;
    }
    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-tabletLandscape) {
        flex: 1;
        margin-bottom: unset;
        display: grid;
      }

      .ImageWrapper {
        position: relative;
        height: 0;
        padding-top: 56.25%;
        align-self: stretch;

        @include mq($breakpoint-tabletLandscape) {
          height: unset;
        }

        .Image {
          position: absolute;
          object-fit: cover;
          left: 0;
          top: 0%;
          height: 100%;
          width: 100%;
        }

        .Logo {
          position: absolute;
          /* stylelint-disable-next-line property-no-unknown */
          aspect-ratio: 1/1;
          max-width: spacing(15) !important;
          left: spacing(2);
          top: 100%;
          transform: translate(0%, calc(-100% - 16px));
          border: solid spacing(0.25) var(--color-theme--event);

          @include mq($breakpoint-desktop) {
            max-width: spacing(30);
          }
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(4);
      padding-bottom: unset;
      padding-left: spacing(2);
      @include mq($breakpoint-tabletLandscape) {
        padding-top: spacing(2);
        padding-left: unset;
        padding-bottom: spacing(2);
        flex: 1;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .Content {
        padding-left: spacing(0);
        padding-right: spacing(2);
        @include mq($breakpoint-tabletLandscape) {
          padding-left: spacing(4);
          padding-right: spacing(4);
        }
        @include mq($breakpoint-tabletLandscape) {
          padding-left: spacing(6);
          padding-right: spacing(6);
        }
      }
    }

    &.inverse {
      .ImagesWrapper {
        @include mq($breakpoint-tabletLandscape) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-tabletLandscape) {
          order: 1;
        }
      }

      .Logo {
        top: 100%;
        left: 100% !important;
        transform: translate(calc(-100% - 16px), calc(-100% - 16px)) !important;
      }
    }
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-primary;
  }
}

.Tag {
  display: inline-flex;
  padding: spacing(0.5) spacing(1);
  background-color: $color-black-primary;
  color: $color-white;
}

.Title {
  font-weight: $font-weight-bold;
  max-width: spacing(60);
  @include mq($breakpoint-tabletLandscape) {
    max-width: unset;
  }
}

.Subtitle {
  color: var(--color-theme--event);
}

.Button {
  background-color: var(--color-theme--event);
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AgendaItemBlock {
  width: 100%;
  height: unset !important;
  @include mq($breakpoint-desktop) {
    padding-top: spacing(8);
    padding-bottom: spacing(8);
  }
  .AgendaItemBlockInner {
    @include mq($breakpoint-tablet) {
      padding-left: spacing(8);
      padding-right: 0;
    }
    @include mq($breakpoint-desktop) {
      display: flex;
      align-items: flex-end;
    }
    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-desktop) {
        flex: 1;
        margin-bottom: unset;
        display: grid;
      }

      .ImageWrapper {
        position: relative;
        height: 0;
        padding-top: 75%;
        align-self: center;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: spacing(1);
          background-color: var(--color-theme--event);
          pointer-events: none;
          z-index: 1;
        }

        .Image {
          position: absolute;
          object-fit: cover;
          left: 0;
          top: 0%;
          height: 100%;
          width: 100%;
        }

        .LogoWrapper {
          position: absolute;
          width: 100%;
          right: 0;
          bottom: 0;
          display: inline-flex;
          align-items: baseline;
          justify-content: flex-end;
          gap: spacing(1);
          padding-right: spacing(2);
          padding-bottom: spacing(2);

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100px;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.7) 0%,
              rgba(0, 0, 0, 0.4) 50%,
              rgba(0, 0, 0, 0) 100%
            );
            pointer-events: none;
            z-index: 0;
          }

          .LogoText {
            color: $color-white;
            z-index: 1;
          }

          .Logo {
            object-fit: contain;
            max-width: spacing(10);
            z-index: 1;
          }
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(2);
      padding-left: spacing(2);
      padding-bottom: spacing(8);
      @include mq($breakpoint-desktop) {
        padding-top: unset;
        padding-left: unset;
        padding-bottom: unset;
        flex: 1;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      @include mq($breakpoint-desktop) {
        flex: 1;
      }

      .Content {
        padding-left: spacing(0);
        padding-right: spacing(2);
        @include mq($breakpoint-desktop) {
          padding-left: spacing(4);
          padding-right: spacing(4);
        }
        @include mq($breakpoint-desktopMedium) {
          padding-left: spacing(6);
          padding-right: spacing(6);
        }

        .Buttons {
          display: flex;
          gap: spacing(1);
        }
      }
    }

    &.inverse {
      @include mq($breakpoint-tablet) {
        padding-right: spacing(8);
        padding-left: 0;
      }

      .ImagesWrapper {
        @include mq($breakpoint-desktop) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-desktop) {
          order: 1;
        }
      }
    }
  }

  &.grey {
    background-color: $color-grey95;
  }

  // eslint-disable-next-line declaration-empty-line-before
  &.dark {
    @include darkMode;

    background-color: $color-black-brand;
  }
  // eslint-enable-next-line declaration-empty-line-before
}

.Tag {
  display: inline-flex;
  padding: spacing(0.5) spacing(1);
  background-color: $color-black-primary;
  color: $color-white;
}

.Title {
  font-weight: $font-weight-bold;
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}

.Subtitle {
  display: inline-flex;
  color: var(--color-theme--event);
  border: 1px solid var(--color-theme--event);
  padding: spacing(0.25) spacing(1);
  margin-right: auto;
}

.InfoRow {
  display: grid;
  gap: spacing(2);

  @include mq($breakpoint-tablet) {
    grid-auto-flow: column;
    justify-content: flex-start;
  }
}

.DateRange,
.Location {
  color: var(--color-theme--event);
  display: inline-flex;
  align-items: center;
  gap: spacing(1);

  .Calendar,
  .Pin {
    position: relative;
    top: -1px;

    svg path {
      fill: var(--color-theme--event);
    }
  }
}

.SpeakersTitle {
  text-transform: uppercase;
}

.Speakers {
  display: grid;
  gap: spacing(1);

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    grid-row: 1fr 1fr;
    gap: spacing(4);
  }
}

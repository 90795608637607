
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.HighlightWrapper {
  font-size: 1em;
  font-weight: 900;

  em {
    font-style: normal;
    font-weight: 400 !important;
  }

  &.small {
    @include mq($breakpoint-desktop) {
      font-size: 0.6em;
      line-height: 1.2em;
      margin-bottom: 0.5em;
    }
  }

  &.medium {
    @include mq($breakpoint-desktop) {
      font-size: 1em;
      line-height: 1.2em;
    }
  }

  &.large {
    @include mq($breakpoint-desktop) {
      font-size: 1.4em;
      line-height: 1.2em;
    }
  }
}

.Highlight {
  color: var(--color-theme--event) !important;
  font-weight: 900;
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.TierOneSponsor {
  position: relative;
  border: 1px solid rgba($color-black-primary, 0.2);

  &.grey {
    background-color: $color-grey95;
    border: none;
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-brand;
    color: $color-white-primary;
    border: none;
  }

  .TierOneSponsorInner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mq($breakpoint-tabletLandscape) {
      flex-direction: row;
      align-items: stretch;
    }

    @include mq($breakpoint-desktop) {
      align-items: flex-end;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
    }

    @include mq($breakpoint-desktopMedium) {
      align-items: stretch;
    }
  }

  &.inverse {
    .TierOneSponsorInner {
      @include mq($breakpoint-desktop) {
        grid-template-columns: 1fr 1fr;
      }
      .Cover {
        @include mq($breakpoint-tabletLandscape) {
          order: 2;
        }
      }
      .ContentWrapper {
        @include mq($breakpoint-tabletLandscape) {
          order: 1;
        }
      }

      .Logo .Image {
        @include mq($breakpoint-tabletLandscape) {
          transform: none;
          right: spacing(1);
          bottom: spacing(1);
          top: unset;
          left: unset;
        }
      }
    }
  }
}

.ContentWrapper {
  @include mq($breakpoint-tabletLandscape) {
    flex: 1;
  }
  @include mq($breakpoint-desktop) {
    flex: 1;
    height: 100%;
  }
}

.Cover {
  height: 100%;

  @include mq($breakpoint-tabletLandscape) {
    flex: 1;
    height: unset;
  }
  @include mq($breakpoint-desktop) {
    flex: 1;
    height: 100%;
  }
  .Image {
    /* stylelint-disable-next-line property-no-unknown */
    object-fit: cover;
    height: 100%;
  }
}

.Content {
  position: relative;
  padding-top: spacing(4);
  padding-bottom: spacing(4);

  @include mq($breakpoint-tabletLandscape) {
    position: unset;
  }

  @include mq($breakpoint-desktop) {
    display: grid;
    height: 100%;
    position: relative;
  }
}

.Stack {
  @include mq($breakpoint-desktop) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Header {
    @include mq($breakpoint-tablet) {
      display: flex;
      gap: spacing(2);
      align-items: flex-end;
      margin-bottom: spacing(1);
    }
  }

  .Tier {
    display: inline-flex;
    color: var(--color-theme--event);
    border: 1px solid var(--color-theme--event);
    padding: spacing(0.25) spacing(1);
    margin-right: auto;
  }

  .DisplayName {
    margin-top: spacing(1);
    margin-bottom: spacing(-1);
  }

  .Highlight {
    color: var(--color-theme--event);
  }

  .Description {
    margin-bottom: spacing(2);
  }

  .Button {
    @include mq($breakpoint-desktop) {
      margin-top: auto;
    }
  }
  & > * + * {
    margin-top: spacing(2);

    @include mq($breakpoint-tabletLandscape) {
      margin-top: spacing(1);
    }

    @include mq($breakpoint-desktop) {
      margin-top: spacing(2);
    }
  }
}

.Logo {
  .Image {
    /* stylelint-disable-next-line property-no-unknown */
    aspect-ratio: 1/1;
    border: solid spacing(0.25) rgba(0, 0, 0, 0.1);
    max-width: spacing(15) !important;
  }
}

.Button {
  background-color: var(--color-theme--event);
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventTestimonialGrid {
  padding-top: spacing(8);
  padding-bottom: spacing(8);

  @include mq($breakpoint-tablet) {
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    background-color: $color-black-primary;
    .Title {
      color: $color-white;
    }
  }
}

.Title {
  text-transform: uppercase;
  .Highlight {
    color: $color-grey-secondary !important;
    font-weight: $font-weight-bold;
  }
}

.GridInner,
.Row {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  @include mq($breakpoint-desktop) {
    display: flex;
    gap: spacing(2);
  }
}

.Row {
  @include mq($breakpoint-desktop) {
    flex-direction: row;
  }
}

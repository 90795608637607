
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Advert {
  text-align: center;
  iframe {
    max-width: 100%;
  }

  > div {
    margin: 0 auto;
    overflow: hidden;
  }
}

.Background {
  @include mq($breakpoint-desktop) {
    background: $color-misc-ad;
  }
}

@include mq($breakpoint-desktop) {
  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
}

.s-320x50 {
  height: 50px;
}

.s-728x90 {
  height: 90px;
  margin: 0.5em 0;
}

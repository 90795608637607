
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SpeakerThumbnail {
  display: flex;
  align-items: center;
  text-align: left;

  .SpeakerThumbnailImageWrapper {
    width: spacing(6);
    height: spacing(6);
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: spacing(1);
    margin-right: spacing(1.5);
    flex-shrink: 0;
    background-color: $color-grey95;

    .SpeakerThumbailImage {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .SpeakerThumbnailJobTitle {
    max-width: spacing(25);
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.LiveVideoPlayerWrapper {
  background-color: rgb(0, 0, 0);
}

.VideoPlayer,
.Cover {
  opacity: 1;
  transform: scaleY(1);
  // transform-origin: top; // Ensure the transform origin is set to top
  height: auto;
  transition: opacity 0.3s ease, transform 0.3s ease;
  background-color: $color-black-primary;
  &.hide {
    opacity: 0;
    transform: scaleY(0);
    // transform-origin: top;
    transition: opacity 0.3s ease, transform 0.3s ease;
    height: 0;
    overflow: hidden;
  }
}

.VideoPlayer {
  background-color: $color-black-brand;
  color: $color-white-primary;

  .VideoPlayerInner {
    padding-top: spacing(2);
    padding-bottom: spacing(2);
    position: relative;
    display: flex;

    @include mq($breakpoint-tablet) {
      padding-top: spacing(4);
      padding-bottom: spacing(4);
    }

    .VideoEmbed {
      height: 0;
      padding-top: 56.25%;
      width: 100%;
      position: relative;
      background-color: black;
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
}

.VideoPlayerStack {
  flex: 1;
  position: relative;
  .EventName {
    display: block;
    color: rgba($color-white-primary, 0.5);
    text-decoration: none;

    &:visited {
      color: rgba($color-white-primary, 0.5);
    }
  }
}

.Cover {
  position: relative;
  color: $color-white-primary;
  width: 100%;

  .CoverInner {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: spacing(4);

    @include mq($breakpoint-desktop) {
      padding-bottom: spacing(8);
    }

    .EventName {
      display: inline-flex;
      padding: spacing(0.25) spacing(0.5);
      color: var(--color-theme--primary);
      background-color: var(--color-theme--event);
      text-decoration: none;
    }

    .PlayButton {
      display: flex;
      flex-direction: column;
      gap: spacing(2);
    }

    .PlayButtonInner {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: spacing(2);

      &.disabled {
        pointer-events: none;

        &:hover {
          cursor: default;
        }
      }

      &:hover,
      &:focus {
        .PlayButtonIcon {
          svg path:nth-child(1) {
            fill: $color-black-brand;
          }
        }
      }

      @include mq($breakpoint-tablet) {
        flex-direction: row;
        align-items: center;
      }

      .PlayButtonIcon {
        @include mq($breakpoint-desktop) {
          height: 90px !important;
          width: 90px !important;
        }
        svg path:nth-child(1) {
          fill: var(--color-theme--event);
        }
      }

      .PlayButtonText {
        max-width: 75vw;
        gap: spacing(1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mq($breakpoint-tablet) {
          gap: spacing(2);
        }
      }
    }
  }
}

// LIVE

.Title {
  display: inline-flex;

  .LiveText {
    color: $color-misc-red;
    margin-right: spacing(1);
    position: relative;
    padding-left: spacing(2);

    &::before {
      content: '🔴';
      position: absolute;
      left: 0;
      top: 50% !important;
      transform: translateY(-75%);
      height: 100%;
      display: inline-block;
      animation: pulse 2s infinite;
      font-size: 0.5rem;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1) translateY(-75%);
  }
  50% {
    transform: scale(1.1) translateY(-75%);
  }
  100% {
    transform: scale(1) translateY(-75%);
  }
}

.Subtitle {
  display: flex;
  flex-direction: column;
  gap: spacing(1);

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    align-items: center;
    gap: spacing(1);
  }

  .SelectControlWrapper {
    order: 1;
    width: spacing(20);
  }

  .EventName {
    order: 2;
  }
}

.HoldingImageWrapper {
  top: 0;
  position: absolute !important;
  width: 100%;
  height: 100% !important;
  padding-top: unset !important;

  img {
    object-fit: cover;
    object-position: center;
  }
}

.LocationSelectWrapper {
  margin-top: spacing(1);

  @include mq($breakpoint-tablet) {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: spacing(2);
    margin-top: spacing(2);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;

    &.visible {
      opacity: 1;
    }
  }
}

.Embed {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  &.hide {
    opacity: 0;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.VideoCard {
  &.center {
    text-align: center;
  }

  &.dark {
    .Title {
      color: $color-white-primary;
    }
  }

  &:hover .VideoCardOverlayLink .Image {
    outline: spacing(0.5) var(--color-theme--event) solid;
  }

  a {
    text-decoration: none;
    &:hover .Title {
      // color: var(--color-theme--event) !important;
    }

    &:hover .Play svg path {
      fill: var(--color-theme--event) !important;
    }
  }
}

.SessionType {
  display: inline-flex;
  padding: 2px 4px;
  gap: spacing(0.5);
  align-items: center;
  color: var(--color-theme--event);
  border: 1px solid var(--color-theme--event);

  .SessionTypeIcon svg path {
    fill: var(--color-theme--event);
    fill-opacity: 1;
  }

  .SessionTypeText {
    position: relative;
    top: 1.5px;
  }
}

.Title {
  color: $color-black-primary;
}

.Subtitle {
  color: $color-grey-secondary;
}

.ImageWrapper {
  position: relative;
  height: 0;
  padding-top: 75%;
  align-self: center;

  .Image {
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0%;
    height: 100%;
    width: 100%;
    transition: outline 0.2s;
    border-radius: spacing(2);
    overflow: hidden;
  }

  .Logo {
    position: absolute;
    left: 95%;
    top: 95%;
    transform: translate(-100%, -100%);
    object-fit: contain;
    // height: 100%;
    width: 100%;
    max-width: spacing(5);
  }

  .Play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonGroup {
  display: flex;
  gap: spacing(1);
  flex-direction: column;
  align-items: flex-start;
  background-color: inherit;

  &.center {
    justify-content: center;
    align-items: center;
  }

  @include mq($breakpoint-desktop) {
    flex-direction: row;
  }

  a {
    text-decoration: none;
  }
}

.Button {
  background-color: var(--color-theme--event) !important;
  color: $color-white-primary !important;

  &:hover,
  &:focus,
  &:active {
    //background-color: $color-grey-secondary !important;
    //color: $color-white-primary;
    //box-shadow: none;
  }
}

.Button.secondary {
  box-shadow: inset 0 0 0 1px var(--color-theme--event) !important;
  color: $color-black-primary !important;
  background: 0 0 !important;

  &::before {
    background-color: var(--color-theme--event);
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-white-primary !important;
    //box-shadow: none !important;
  }
}

.Button.tertiary {
  background-color: $color-black-primary !important;

  * {
    color: $color-white-primary !important;
  }

  &::before {
    background-color: rgba($color-white-primary, 0.1);
  }

  &.dark {
    background-color: $color-white-primary !important;

    &::before {
      background-color: $color-black-primary;
    }

    * {
      color: $color-black-primary !important;
      transition: color 250ms ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      * {
        color: $color-white-primary !important;
      }
    }
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ImageWrapper {
  height: 0;
  padding-top: 100%;
  position: relative;

  &.ignoreWrapper {
    width: inherit;
    height: inherit;
    left: 0;
    top: 0;
    padding-top: inherit;
    position: absolute;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }

  @include mq($breakpoint-tablet) {
    padding-top: 42.9%;
  }

  .Image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .Loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $color-black-primary;
    animation: pulsate 2s infinite; // Apply the animation

    .Label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white-primary;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;

      &::after {
        content: '';
        display: inline-flex;
        width: 10px;
        color: var(--color-theme--event);
        animation: loadingDots 1s infinite steps(3);
      }

      .Stage {
        font-weight: 700;
        color: var(--color-theme--event);
        font-style: italic;
        margin-left: 0.25em;
      }
    }

    @keyframes pulsate {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.9;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes loadingDots {
      0% {
        content: '';
      }
      33% {
        content: '.';
      }
      66% {
        content: '..';
      }
      100% {
        content: '...';
      }
    }
  }
}

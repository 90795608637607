
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventCard {
  &.dark {
    .Title {
      color: $color-white-primary;
    }

    .Cta:hover {
      color: $color-white-primary;
    }
  }

  &.center {
    text-align: center;
  }

  &:hover .EventCardOverlayLink .Image {
    outline: spacing(0.5) var(--color-theme--event) solid;
  }

  a {
    text-decoration: none;
    &:hover .Title {
      color: var(--color-theme--event) !important;
    }

    &:hover .Play svg path {
      fill: var(--color-theme--event) !important;
    }
  }
}

.EventType {
  background-color: var(--color-theme--event);
  display: inline-flex;
  justify-content: flex-start;
  padding: spacing(0.25) spacing(1.25);
  .EventTypeLabel {
    color: $color-white-primary;
    line-height: 1.8em;
  }
}

.TextContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(0.5);
}

.Title {
  color: $color-black-primary;
}

.Subtitle {
  color: $color-grey50;
}

.Cta {
  display: block;
  color: var(--color-theme--event);
  * {
    text-decoration: underline;
  }

  &:hover {
    color: var(--color-theme--primary);
  }
}

.ImageWrapper {
  position: relative;
  height: 0;
  padding-top: 75%;
  align-self: center;

  .Image {
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0%;
    height: 100%;
    width: 100%;
    transition: outline 0.2s;
  }

  .Logo {
    position: absolute;
    left: 95%;
    top: 95%;
    transform: translate(-100%, -100%);
    object-fit: contain;
    // height: 100%;
    width: 100%;
    max-width: spacing(5);
  }

  .Play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.EventCardInner {
  &.speaker,
  &.sponsor {
    .ImagesWrapper {
      margin-left: auto;
      margin-right: auto;
    }

    .ImageWrapper {
      padding-top: 100%;
    }

    .Title,
    .Subtitle {
      text-align: center;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.speaker {
    .ImagesWrapper {
      max-width: 50%;
    }
    .ImageWrapper .Image {
      border-radius: 100%;
      overflow: hidden;
    }
  }

  &.sponsor {
    .ImagesWrapper {
      @include mq($breakpoint-desktop) {
        max-width: 60%;
      }
    }
  }
}

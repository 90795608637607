
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Captcha {
  display: flex;
  padding: spacing(4) 0;
  overflow: hidden;
  @include mq($breakpoint-tabletLandscape) {
    padding: spacing(7) 0 spacing(6);
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.PlayButton {
  padding: spacing(1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.clickable:hover,
  &.clickable:focus {
    svg path {
      fill: var(--color-theme--event);
    }
  }

  &.clickable:active {
    svg path {
      filter: brightness(0.7);
    }
  }
}

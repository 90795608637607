
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventAgenda {
  position: relative;
  color: $color-white-primary;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  background-color: rgba(black, 0.95);

  @include mq($breakpoint-tablet) {
    padding-top: spacing(10);
    padding-bottom: spacing(10);
  }

  // A bit janky and likely to break if they change their attribute names
  div[data-testid='container'] {
    & > div > div,
    & > div > div > div {
      background: none;
    }
  }

  .Container {
    z-index: 1;
  }
}

.EventAgendaImage {
  z-index: -1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.ChannelItem {
  color: $color-white-primary;
  height: 80px;
  position: relative;
  backdrop-filter: blur(4px);

  .Text {
    position: relative;
    height: 80%;
    padding-left: spacing(2);
    padding-right: spacing(2);
    border-left: solid spacing(0.5) var(--color-theme--event);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventCountdown {
  background-color: $color-black-primary;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  color: $color-white-primary;
  position: sticky;
  top: 0;
}

.EventCountdownInner,
.Content {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
}

.EventCountdownInner {
  @include mq($breakpoint-tabletLandscape) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.Content {
  @include mq($breakpoint-tabletLandscape) {
    gap: spacing(1);
  }
}

.Divider {
  color: var(--color-theme--event);
  display: flex;
  align-items: center;

  .DividerText {
    margin-right: 10px;
    letter-spacing: 0.6ex;
  }

  hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid currentColor;
    margin-bottom: spacing(0.25);
  }
}

.Title {
  font-weight: $font-weight-extrabold;
  text-transform: uppercase;
  font-style: italic;
}

.InfoWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(1);

  @include mq($breakpoint-tabletLandscape) {
    flex-direction: row;
    gap: spacing(4);
  }
}

.Location,
.Date {
  display: flex;
  gap: spacing(1.5);
}

.Dot {
  position: relative;
  display: none;
  @include mq($breakpoint-tabletLandscape) {
    color: $color-white-primary;
    display: block;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    color: $color-white-primary;
    background-color: $color-grey-secondary;
    border-radius: 100px;
    height: 6px;
    width: 6px;
    top: 40%;
    transform: translateY(-50%);
  }
}

.Calendar {
  margin-top: spacing(-0.25);

  svg path {
    fill: var(--color-theme--event);
  }
}

.Pin {
  svg path {
    fill: var(--color-theme--event);
  }
}

.Info {
  color: $color-white-primary;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.BoxWrapper {
  display: flex;
  gap: spacing(1);
}

.Box {
  border: 2px solid var(--color-theme--event);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: spacing(7);
  height: spacing(7);

  @include mq($breakpoint-desktop) {
    width: spacing(10);
    height: spacing(10);
  }

  .Number {
    font-style: italic;
  }

  .Unit {
    text-transform: uppercase;
    color: $color-grey-secondary;
  }
}

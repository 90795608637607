
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventAgendaModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalWrapper {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: max-content !important;
  height: max-content;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: spacing(2);
  margin-right: spacing(2);

  .CloseButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: $color-black-primary;
    height: 60px;
    width: 60px;
    border-left: solid spacing(0.5) var(--color-theme--event);
    border-bottom: solid spacing(0.5) var(--color-theme--event);

    &:hover,
    &:focus,
    &:active {
      background-color: $color-black-secondary;
    }

    svg path:nth-child(1) {
      fill: $color-white-primary;
    }
  }
}

.ModalInner {
  position: relative;
  border-top: solid spacing(0.5) var(--color-theme--event);
  color: $color-white-primary;
  background-color: $color-black-primary;
  padding: spacing(4);
  text-align: left;

  @include mq($breakpoint-tablet) {
    padding: spacing(10);
  }
}

.SessionType {
  color: var(--color-theme--event);
  border: 1px solid var(--color-theme--event);
  padding: spacing(1);
}

.Description {
  opacity: 0.75;
}

.InfoRow {
  display: grid;
  gap: spacing(2);

  @include mq($breakpoint-tablet) {
    grid-auto-flow: column;
    justify-content: flex-start;
  }
}

.DateRange,
.Location {
  color: var(--color-theme--event);
  display: inline-flex;
  align-items: center;
  gap: spacing(1);

  .Calendar,
  .Pin {
    position: relative;
    top: -1px;

    svg path {
      fill: $color-white-primary;
    }
  }
}

.SpeakersTitle {
  text-transform: uppercase;
}

.Speakers {
  display: grid;

  @include mq($breakpoint-tablet) {
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    grid-row: 1fr 1fr;
    gap: spacing(4);
  }
}

.Buttons {
  display: flex;
  gap: spacing(1);
  flex-wrap: wrap;
}

.Tag {
  color: $color-black-primary;
  background-color: var(--color-theme--event);
}

.Overlay {
  position: absolute;
  background-color: rgba($color-black-primary, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}

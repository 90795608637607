
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventMagazineRow {
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  @include mq($breakpoint-desktop) {
    padding-top: spacing(4);
    padding-bottom: spacing(4);
  }
  &.dark {
    background-color: $color-black-primary;
  }
}

.EventMagazineRowContainer {
  display: flex;
  overflow-x: scroll;
  gap: spacing(1);
  @include mq($breakpoint-desktop) {
    gap: spacing(2);
  }

  .ImageWrapper {
    min-width: spacing(20);
    @include mq($breakpoint-desktop) {
      min-width: unset;
    }
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FieldFeedback {
  margin-top: spacing(0.5);
  font-size: 16px;
}

@each $key, $value in $color-status-list {
  .#{$key} {
    color: $value !important;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventStats {
  position: relative;
  padding: spacing(5) 0;
  @include mq($breakpoint-desktop) {
    padding: spacing(8) 0;
  }

  .EventStatsImage {
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.03;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    background-color: $color-black-primary;
    color: $color-white-primary;

    .Stats {
      color: $color-white-primary;
    }
  }
}

.EventStatsInner {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.Title {
  text-align: center;
  font-weight: $font-weight-extrabold;
}

.Stats {
  width: 100%;
  @include mq($breakpoint-tabletLandscape) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.StatWrapper {
  text-align: center;
  padding-bottom: spacing(3.5);
  margin-left: auto;
  margin-right: auto;

  @include mq($breakpoint-tabletLandscape) {
    min-width: 180px;
    text-align: center;
  }

  @include mq($breakpoint-desktop) {
    min-width: 220px;
  }
}

.Stat {
  position: relative;
  margin-bottom: spacing(1.5);
  padding-bottom: spacing(1);
  color: var(--color-theme--event);

  @include mq($breakpoint-tabletLandscape) {
    &::after {
      width: 100%;
      right: unset;
    }
  }

  @include mq($breakpoint-desktop) {
    padding-bottom: 0;
  }
}

.Label {
  max-width: 75%;
  margin: 0 auto;
}

.Description {
  max-width: spacing(100);
}

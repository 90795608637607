
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SelectControlWrapper {
  width: spacing(30);

  &:hover {
    .SelectControl {
      outline: spacing(0.125) var(--color-theme--event) solid;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.2;
    transition: opacity 0.5s ease-in-out;
  }

  span,
  span select {
    font-size: inherit;
  }

  select {
    padding-top: spacing(0.5);
    padding-bottom: spacing(0.5);
    height: unset;
  }

  .SelectControl {
    background-color: unset;
    box-shadow: none;
    font-size: inherit;

    select {
      font-size: inherit;
      transition: unset;
    }
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.TimelineWrapper {
  position: relative;

  .CurrentTimeLine {
    content: '';
    left: 1000px;
    position: absolute;
    top: 60px;
    width: 4px;
    height: calc(240px - 60px);
    background: var(--color-theme--event);
  }
}

.TimelineBox {
  &.bold {
    .TimelineTime {
      font-weight: $font-weight-bold !important;
      color: var(--color-theme--event) !important;
    }

    .TimelineDivider:nth-child(1) {
      background: var(--color-theme--event) !important;
      position: relative;
    }
  }
}

.TimelineTime {
  left: -22px !important;
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.AgendaItemCard {
  display: grid;
  grid-template-columns: 1fr;
  gap: spacing(2);
  position: relative;
  align-items: center;
  padding: spacing(2);

  &:not(.DateLabel) {
    background-color: rgba($color-black-primary, 0.05);

    &.shaded {
      background-color: rgba($color-black-primary, 0.15);

      &:hover,
      &:active,
      &:focus {
        background-color: rgba($color-black-primary, 0.2);
      }
    }
  }

  &:not(.DateLabel):hover,
  &:not(.DateLabel):active,
  &:not(.DateLabel):focus {
    background-color: rgba($color-black-primary, 0.2);
    cursor: pointer;
  }

  &.dark {
    &:not(.DateLabel) {
      background-color: $color-white-primary;

      &.shaded {
        background-color: rgba($color-white-primary, 0.8);

        &:hover,
        &:active,
        &:focus {
          background-color: rgba($color-white-primary, 0.75);
        }
      }
    }

    &:not(.DateLabel):hover,
    &:not(.DateLabel):active,
    &:not(.DateLabel):focus {
      background-color: rgba($color-white-primary, 0.95);
      cursor: pointer;
    }
  }

  &.live {
    background-color: #f00;
    animation: pulsate 1.5s infinite;
    color: $color-white-primary;
    &:hover,
    &:active,
    &:focus {
      background-color: #8d1800 !important;
    }

    .SessionType,
    .StageName {
      color: $color-white-primary;
    }
  }

  &.hideStage {
    grid-template-columns: 1fr;
    @include mq($breakpoint-tablet) {
      grid-template-columns: 1fr 3fr 3fr 1fr;
    }
  }

  @keyframes pulsate {
    0% {
      background-color: #f00; // Bright red
    }
    50% {
      background-color: #b51e00; // Darker red
    }
    100% {
      background-color: #f00; // Bright red
    }
  }

  @include mq($breakpoint-tablet) {
    grid-template-columns: 2fr 2fr 3fr 3fr 1fr;
    gap: 0;
    max-width: spacing(136);
  }

  &.center {
    @include mq($breakpoint-tablet) {
      margin-left: auto;
      margin-right: auto;

      &.DateLabel {
        text-align: center;
      }
    }
  }

  .Name {
    padding-right: spacing(1);
  }

  .SessionType {
    text-transform: uppercase;
    color: var(--color-theme--event);
    text-align: center;
  }

  .StageName {
    text-transform: uppercase;
    color: var(--color-theme--event);
    text-align: left;

    @include mq($breakpoint-tablet) {
      padding-left: spacing(1);
    }
  }

  .SpeakersWrapper {
    display: flex;
    flex-direction: column;
    .Speaker {
      @include mq($breakpoint-desktop) {
        display: flex;
        align-items: center;
        gap: spacing(0.5);
      }
    }

    .SpeakerDivider {
      display: none;
      @include mq($breakpoint-desktop) {
        display: block;
      }
    }
  }
}

.DateLabel {
  background-color: var(--color-theme--primary);
  color: $color-white-primary;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: spacing(1) spacing(2);
  @include mq($breakpoint-tablet) {
    grid-template-columns: 1fr;
  }
}

.SmartLink {
  text-decoration: none;
}

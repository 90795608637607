
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ChevronTitle {
  display: flex;
  align-items: flex-start;

  .ArrowIcon {
    display: block;
    margin-right: spacing(2);
    position: relative;
    bottom: -5px;

    &.shiftDown {
      bottom: -8px;
    }

    svg path {
      fill: var(--color-theme--secondary);
    }

    @include mq($breakpoint-desktop) {
      width: 36px !important;
      height: 31px !important;
      line-height: 31px !important;
      margin-right: spacing(3);
      bottom: -9px;

      &.shiftDown {
        bottom: -14px;
      }
    }
  }
}

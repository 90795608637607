
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventButtonGroup {
  display: flex;
  justify-content: flex-start;

  &.center {
    justify-content: center;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventTextBlock {
  position: relative;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  .EventTextBlockInner {
    position: relative;
    @include mq($breakpoint-tablet) {
      display: flex;
    }
    .ContentWrapper {
      padding-top: spacing(8);
      padding-bottom: spacing(8);
      .Content {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-primary;
  }

  &.center {
    .ContentWrapper {
      margin-left: auto;
      margin-right: auto;
    }
    .Content {
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
}

.Title {
  font-weight: $font-weight-extrabold;
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}


      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventImageSplitHero {
  position: relative;
  background-color: $color-white-primary;
  .EventImageSplitHeroInner {
    @include mq($breakpoint-tablet) {
      display: flex;
      min-height: 350px;
    }

    @include mq($breakpoint-desktop) {
      min-height: 450px;
    }

    @include mq($breakpoint-desktopMedium) {
      min-height: 550px;
    }

    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-tablet) {
        flex: 1;
        margin-bottom: unset;
      }

      .ImageWrapper {
        position: relative;

        .Image {
          object-fit: cover;
          position: relative;
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(8);
      padding-bottom: spacing(8);
      @include mq($breakpoint-tablet) {
        flex: 1.5;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include mq($breakpoint-desktop) {
        flex: 1;
      }

      .Content {
        padding-left: spacing(2);
        padding-right: spacing(2);
        @include mq($breakpoint-tablet) {
          padding-left: spacing(4);
          padding-right: spacing(4);
        }
        @include mq($breakpoint-desktopMedium) {
          padding-left: spacing(6);
          padding-right: spacing(6);
          max-width: spacing(80);
        }
      }
    }

    &.inverse {
      .ImagesWrapper {
        @include mq($breakpoint-tablet) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-tablet) {
          order: 1;
        }
      }
    }
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-brand;
  }
}

.Title {
  font-weight: $font-weight-extrabold;
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}

.Description {
  p {
    font-size: inherit !important;
  }

  li {
    font-size: 0.8em !important;

    &::marker {
      color: var(--color-theme--event);
    }
  }
}

.EventImageSplitHero.cover {
  .Image {
    top: unset;
    transform: unset;
    height: 100%;
  }
}

.EventImageSplitHero.column {
  padding-top: 0;

  .EventImageSplitHeroInner {
    display: grid;
    gap: spacing(2);
  }

  .ContentWrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .Content {
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include mq($breakpoint-tablet) {
      padding-left: spacing(2);
      padding-right: spacing(2);
    }
  }

  .OverflowContent {
    order: 3;
  }

  .ImageWrapper {
    margin-left: auto;
    margin-right: auto;
    /* stylelint-disable-next-line property-no-unknown */
    aspect-ratio: 4/3;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.05);

    .Image {
      height: unset !important;
      object-fit: unset !important;
      top: unset !important;
      width: 100%;
      /* stylelint-disable-next-line property-no-unknown */
      aspect-ratio: 4/3;
      position: relative !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }

  @include mq($breakpoint-tablet) {
    padding-top: spacing(4);

    .Description {
      text-align: center;
      order: 3;

      li {
        list-style-position: inside;
      }
    }

    .ButtonGroup {
      justify-content: center;
    }
  }
}

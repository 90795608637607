
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventDetails {
  background: lightblue;
  padding-top: spacing(6);
  padding-bottom: spacing(4);
  background-color: white;
  position: relative;
  color: $color-black-primary;

  .EventDetailsImage {
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.03;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @include mq($breakpoint-desktop) {
    padding-top: spacing(8);
    padding-bottom: spacing(8);
  }

  &.grey {
    background-color: $color-grey95;
  }

  &.dark {
    background-color: $color-black-primary;
    & * {
      color: white;
    }
  }

  &.forceThreeColumn {
    @include mq($breakpoint-tablet) {
      .EventDetailsInner {
        align-items: flex-start;
      }
    }

    .Grid {
      @include mq($breakpoint-desktop) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: spacing(8);
      }
      @include mq($breakpoint-desktop) {
        .EventDetail {
          flex-basis: 30% !important;
          max-width: unset;
        }
      }
    }
  }
}

.Grid {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq($breakpoint-tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: spacing(4);
    flex-wrap: wrap;

    .EventDetail {
      flex-basis: 33%;
    }
  }

  @include mq($breakpoint-desktop) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq($breakpoint-desktop) {
    .EventDetail {
      flex-basis: 25%;
    }
  }
}

.EventDetailsContainer {
  .EventDetailsInner {
    @include mq($breakpoint-tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  & * {
    z-index: 2;
  }
  .EventDetail {
    display: grid;
    gap: spacing(3);
    justify-content: space-between;
    /* stylelint-disable-next-line selector-type-no-unknown */
    &:not(first-child) {
      margin-top: spacing(2);
    }
    @include mq($breakpoint-desktop) {
      display: flex;
      gap: spacing(1);
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      max-width: spacing(32);
      /* stylelint-disable-next-line selector-type-no-unknown */
      &:not(first-child) {
        margin-top: unset;
      }
    }
  }

  .Title {
    display: inline-flex;
    align-items: center;

    &.column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: spacing(2);
    }

    .IconWrapper {
      position: relative;
      margin-right: spacing(2);

      &::before {
        content: '\2B22';
        color: var(--color-theme--event);
        font-size: 4em;
        position: absolute;
        left: 50%;
        top: calc(50% + 2px);
        -webkit-transform: translate(-50%, -50%) rotate(-15deg);
        -moz-transform: translate(-50%, -50%) rotate(-15deg);
        -o-transform: translate(-50%, -50%) rotate(-15deg);
        transform: translate(-50%, -50%) rotate(-15deg);

        @include mq($breakpoint-desktop) {
          font-size: 3.5em;
        }
      }

      .Icon {
        display: flex;
      }

      svg {
        path {
          fill: $color-white-primary;
        }
      }
    }
  }

  .Description p {
    font-size: inherit !important;
  }

  .Button {
    margin-top: spacing(2);
  }
}

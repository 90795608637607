
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventFeaturedTestimonial {
  position: relative;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  .EventFeaturedTestimonialInner {
    position: relative;
    @include mq($breakpoint-tablet) {
      display: flex;
      justify-content: center;
    }
    .ImagesWrapper {
      & > * {
        height: 100%;
      }

      @include mq($breakpoint-tablet) {
        flex: 1;
        margin-bottom: unset;
      }

      .ImageWrapper {
        position: relative;

        .Image {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    .ContentWrapper {
      padding-top: spacing(8);
      padding-bottom: spacing(8);
      text-align: center;

      .Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &.inverse {
      .ImagesWrapper {
        @include mq($breakpoint-tablet) {
          order: 2;
        }
      }

      .ContentWrapper {
        @include mq($breakpoint-tablet) {
          order: 1;
        }
      }
    }
  }

  &.dark {
    @include darkMode;

    background-color: $color-black-primary;
  }
}

.EventFeaturedTestimonialImage {
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: red;
}

.Headline {
  max-width: spacing(60);
  @include mq($breakpoint-desktop) {
    max-width: unset;
  }
}

.QuoteeOverride {
  text-decoration: underline;
  text-decoration-color: var(--color-theme--event);
}
